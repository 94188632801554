// File#: _1_overscroll-section
// Usage: codyhouse.co/license
(function() {
    var OverscrollSection = function(element) {
      this.element = element;
      this.stickyContent = this.element.getElementsByClassName('js-overscroll-section__sticky-content');
      this.scrollContent = this.element.getElementsByClassName('js-overscroll-section__scroll-content');
      this.scrollingFn = false;
      this.scrolling = false;
      this.resetOpacity = false;
      this.disabledClass = 'overscroll-section--disabled';
      initOverscrollSection(this);
    };
  
    function initOverscrollSection(element) {
      // set position of sticky element
      setTop(element);
      // create a new node - to be inserted before the scroll element
      createPrevElement(element);
      // on resize -> reset element top position
      element.element.addEventListener('update-overscroll-section', function(){
        setTop(element);
        setPrevElementTop(element);
      });
      // set initial opacity value
      animateOverscrollSection.bind(element)(); 
      // change opacity of layer
      var observer = new IntersectionObserver(overscrollSectionCallback.bind(element));
      observer.observe(element.prevElement);
    };
  
    function createPrevElement(element) {
      if(element.scrollContent.length == 0) return;
      var newElement = document.createElement("div"); 
      newElement.setAttribute('aria-hidden', 'true');
      element.element.insertBefore(newElement, element.scrollContent[0]);
      element.prevElement =  element.scrollContent[0].previousElementSibling;
      element.prevElement.style.opacity = '0';
      setPrevElementTop(element);
    };
  
    function setPrevElementTop(element) {
      element.prevElementTop = element.prevElement.getBoundingClientRect().top + window.scrollY;
    };
  
    function overscrollSectionCallback(entries) {
      if(entries[0].isIntersecting) {
        if(this.scrollingFn) return; // listener for scroll event already added
        overscrollSectionInitEvent(this);
      } else {
        if(!this.scrollingFn) return; // listener for scroll event already removed
        window.removeEventListener('scroll', this.scrollingFn);
        updateOpacityValue(this, 0);
        this.scrollingFn = false;
      }
    };
  
    function overscrollSectionInitEvent(element) {
      element.scrollingFn = overscrollSectionScrolling.bind(element);
      window.addEventListener('scroll', element.scrollingFn);
    };
  
    function overscrollSectionScrolling() {
      if(this.scrolling) return;
      this.scrolling = true;
      window.requestAnimationFrame(animateOverscrollSection.bind(this));
    };
  
    function animateOverscrollSection() {
      if(this.stickyContent.length == 0) return;
      setPrevElementTop(this);
      if( parseInt(this.stickyContent[0].style.top) != window.innerHeight - this.stickyContent[0].offsetHeight) {
        setTop(this);
      }
      if(this.prevElementTop - window.scrollY < window.innerHeight*2/3) {
        var opacity = Math.easeOutQuart(window.innerHeight*2/3 + window.scrollY - this.prevElementTop, 0, 1, window.innerHeight*2/3);
        if(opacity > 0 ) {
          this.resetOpacity = false;
          updateOpacityValue(this, opacity);
        } else if(!this.resetOpacity) {
          this.resetOpacity = true;
          updateOpacityValue(this, 0);
        } 
      } else {
        updateOpacityValue(this, 0);
      }
      this.scrolling = false;
    };
  
    function updateOpacityValue(element, value) {
      element.element.style.setProperty('--overscroll-section-opacity', value);
    };
  
    function setTop(element) {
      if(element.stickyContent.length == 0) return;
      var translateValue = window.innerHeight - element.stickyContent[0].offsetHeight;
      element.stickyContent[0].style.top = translateValue+'px';
      // check if effect should be disabled
      Util.toggleClass(element.element, element.disabledClass, translateValue > 2);
    };
  
    //initialize the OverscrollSection objects
    var overscrollSections = document.getElementsByClassName('js-overscroll-section');
    var stickySupported = Util.cssSupports('position', 'sticky') || Util.cssSupports('position', '-webkit-sticky'),
      intObservSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype),
      reducedMotion = Util.osHasReducedMotion();
      if( overscrollSections.length > 0 && stickySupported && !reducedMotion && intObservSupported) {
      var overscrollSectionsArray = [];
          for( var i = 0; i < overscrollSections.length; i++) {
        (function(i){overscrollSectionsArray.push(new OverscrollSection(overscrollSections[i]));})(i);
      }
      
      var resizingId = false,
        customEvent = new CustomEvent('update-overscroll-section');
  
      window.addEventListener('resize', function() {
        clearTimeout(resizingId);
        resizingId = setTimeout(doneResizing, 100);
      });
  
      // wait for font to be loaded
      document.fonts.onloadingdone = function (fontFaceSetEvent) {
        doneResizing();
      };
  
      function doneResizing() {
        for( var i = 0; i < overscrollSectionsArray.length; i++) {
          (function(i){overscrollSectionsArray[i].element.dispatchEvent(customEvent)})(i);
        };
      };
      }
  }());

  // File#: _2_product-v3
// Usage: codyhouse.co/license
(function() {
    var ProductV3 = function(element) {
      this.element = element;
      this.cta = this.element.getElementsByClassName('js-product-v3__cta');
      this.ctaClone = this.element.getElementsByClassName('js-product-v3__cta-clone');
      this.ctaVisible = false;
      this.sectionVisible = false;
      this.cloneDelta = '200px'; // small screen only - clone element visible when section enters the viewport of 200px
  
      // quantity inputs
      this.quantity = this.element.getElementsByClassName('js-product-v3__input');
      this.quantityClone = this.element.getElementsByClassName('js-product-v3__input-clone');
      initProductV3(this);
    };
  
    function initProductV3(product) {
      if(product.ctaClone.length == 0) return;
      if(product.cta.length > 0 && intObservSupported) { // detect when cta enters viewport
        var observer = new IntersectionObserver(observeCta.bind(product), { threshold: [0, 1] });
        observer.observe(product.cta[0]);
      }
      if(intObservSupported) {
        // detect when product section enters the viewport
        var sectionObserver = new IntersectionObserver(observeSection.bind(product), {
          rootMargin: "0px 0px -"+product.cloneDelta+" 0px"
        });
        sectionObserver.observe(product.element);
      }
  
      // sync quantity input with clone element
      if(product.quantity.length > 0 && product.quantityClone.length > 0) syncProductQuantity(product);
    };
  
    function observeCta(entries) {
      if(entries[0].isIntersecting) {
        this.ctaVisible = true;
        Util.removeClass(this.ctaClone[0], 'product-v3__cta-clone--is-visible');
      } else if(this.sectionVisible) {
        this.ctaVisible = false;
        Util.addClass(this.ctaClone[0], 'product-v3__cta-clone--is-visible');
      }
    };
  
    function observeSection(entries) {
      if(entries[0].isIntersecting) {
        this.sectionVisible = true;
      } else {
        this.sectionVisible = false;
        Util.removeClass(this.ctaClone[0], 'product-v3__cta-clone--is-visible');
      }
    };
  
    function syncProductQuantity(product) {
      product.quantity[0].addEventListener('change', function(){
        product.quantityClone[0].value = getAllowedValue(product, parseInt(product.quantity[0].value));
      });
      product.quantityClone[0].addEventListener('change', function(){
        product.quantity[0].value = getAllowedValue(product, parseInt(product.quantityClone[0].value));
      });
    };
  
    function getAllowedValue(product, value) {
      var min = product.quantity[0].getAttribute('min'),
        max = product.quantity[0].getAttribute('max');
      if(min && value < parseInt(min)) value = min;
      if(max && value > parseInt(max)) value = max;
      return value;
    };
  
    //initialize the ProductV3 objects
    var productV3 = document.getElementsByClassName('js-product-v3'),
      intObservSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype);
    if( productV3.length > 0 ) {
      for( var i = 0; i < productV3.length; i++) {
        (function(i){new ProductV3(productV3[i]);})(i);
      }
    };
  }());